/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react'
import Layout from '../../components/layout'
import { withContext } from '../../contexts/withContext'

class ThankYou extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    document.title = 'Application submitted'
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  render() {
    const branch = this.getParameterByName('b', this.props.location.search)
    const origin = this.getParameterByName('origin', this.props.location.search)
    // console.log(this.props.location,branch)
    return (
      <Layout title="Success" branch={branch}>
        <h1 style={{ border: 'none' }} className="my-5 text-center">
          Your application has been submitted
        </h1>
        {origin === 'application' ? (
          <h3 className="mb-5 text-center">
            The admissions office will contact you within 5 business days to acknowledge receipt of your application
            <br />
            <br />
            If you do not receive an email within 5 business days, please check the junk/spam folder in your email. If you have not received anything, email admissions@ics.ac.th.
          </h3>
        ) : null}
      </Layout>
    )
  }
}

export default withContext(ThankYou)
