import React, { Component } from 'react'

class NotFound extends Component {
  render() {
    const path = this.props.location.pathname
    return (
      <div className="p-2">
        <h1 style={{ border: 'none' }}>Not Found</h1>
        <h3>The requested URL {path} was not found</h3>
      </div>
    )
  }
}

export default NotFound
