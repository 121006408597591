/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-globals */
import _ from 'lodash'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'

class FormInput extends Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
  }

  // componentDidMount() {
  //   const { choices, radioChoices, onChange, value } = this.props
  //   // if (choices && choices.length > 0 && this.isUnselected(value)) {
  //   // onChange(choices[0].value)
  //   // }
  //   if (radioChoices && radioChoices.length > 0 && this.isUnselected(value)) {
  //     onChange(radioChoices[0].value)
  //   }
  // }

  // componentDidUpdate(prevProps) {
  //   const { choices, radioChoices, value } = this.props
  //   // if (choices && choices.length > 0 && this.isUnselected(value)) {
  //   // this.props.onChange(choices[0].value)
  //   // }
  //   if (radioChoices && radioChoices.length > 0 && this.isUnselected(value)) {
  //     this.props.onChange(radioChoices[0].value)
  //   }
  // }

  isUnselected = v => v === '' || v === undefined

  parseDate = str => {
    if (str === '') {
      return null
    }
    const d = Date.parse(str)
    return isNaN(d) ? null : new Date(d)
  }

  render() {
    const { placeholder, textarea, fieldName, labelClassName, value, errors, choices, radioChoices, radioChoicesExtra, onChange, className, accept, YN, check, DP, DPprops, inputProps, selectProps, inputAppend, pdpa } = this.props
    let { type } = this.props
    if (type === undefined) {
      type = 'text'
    }
    const haveError = errors && _.isArray(errors)
    if (check) {
      return (
        // <div className="row h-100 d-flex align-items-center justify-content-start px-3">
        !pdpa ? (
          <div className="form-check">
            <label className={`form-check-label ml-2 ${labelClassName || ''}`}>
              <input
                className="form-check-input"
                type="checkbox"
                checked={value === true}
                onChange={e => {
                  onChange(!value)
                }}
                {...inputProps}
              />
              {fieldName ? <span className="">{fieldName || value.charAt(0).toUpperCase() + value.slice(1)}</span> : null}
            </label>
          </div>
        ) : (
          <div className={`d-flex flex-column`}>
            <label className={`px-3 d-flex align-items-center ${errors ? 'invalid-radio' : ''} ${labelClassName || ''}`}>
              <input
                className=""
                type="checkbox"
                checked={value === true}
                onChange={e => {
                  onChange(!value)
                }}
                {...inputProps}
              />
              {fieldName ? <span className="pl-3 font-weight-bold">{fieldName || value.charAt(0).toUpperCase() + value.slice(1)}</span> : null}
            </label>
            {haveError
              ? errors.map(e => (
                  <div key={e} className="invalid-feedback d-block">
                    <small>{e}</small>
                  </div>
                ))
              : null}
          </div>
        )
      )
    }
    if (DP) {
      return (
        <div className="form-group d-flex flex-column">
          {fieldName ? <label className={labelClassName}>{fieldName || value.charAt(0).toUpperCase() + value.slice(1)}</label> : null}
          <DatePicker
            onChange={d => {
              if (d !== null) {
                d.setHours(12)
                d.setMinutes(0)
                d.setSeconds(0)

                // console.log(d)
                const tzoffset = d.getTimezoneOffset() * 60000
                const localISOTime = new Date(d - tzoffset)
                // console.log(localISOTime)
                // console.log(localISOTime.toISOString())
                onChange(localISOTime.toISOString())
              } else {
                onChange('')
              }
            }}
            selected={this.parseDate(value)}
            dateFormat="dd MMM yyyy"
            className={`w-100 form-control form-control-sm ${errors ? 'is-invalid' : ''}`}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={20}
            {...DPprops}
          />
          {haveError
            ? errors.map(e => (
                <div key={e} className="invalid-feedback select-invalid-feedback">
                  <small>{e.replace('Date has wrong format. Use one of these formats instead: YYYY-MM-DD, YYYY-MM-DDThh:mm:ss.uuuuuuZ.', 'This field may not be blank.')}</small>
                </div>
              ))
            : null}
        </div>
      )
    }
    if (YN) {
      return (
        <div className="row h-100 d-flex align-items-center justify-content-start px-3">
          {fieldName ? <span>{fieldName || value.charAt(0).toUpperCase() + value.slice(1)}</span> : null}
          <span className="ml-3">
            <a
              className={`yn ${value === true ? 'active' : ''}`}
              onClick={() => {
                if (value === true) {
                  onChange('')
                } else {
                  onChange(true)
                }
              }}
            >
              YES
            </a>
            <span> / </span>
            <a
              className={`yn ${value === false ? 'active' : ''}`}
              onClick={() => {
                if (value === false) {
                  onChange('')
                } else {
                  onChange(false)
                }
              }}
            >
              NO
            </a>
          </span>
        </div>
      )
    }
    if (radioChoices !== undefined) {
      return (
        <div>
          {fieldName || ''}
          <div className={`form-group px-2 ${errors ? 'invalid-radio' : ''}`} style={{ width: 'fit-content' }}>
            {radioChoices.map((rc, idx) => {
              return (
                <div className={`form-check ${className || ''}`} key={rc.value}>
                  <label className="form-check-label">
                    <input className="form-check-input" type="radio" value={rc.value} checked={String(rc.value) === String(value)} onChange={e => onChange(e.currentTarget.value)} />
                    {rc.fullLabel || rc.label}
                  </label>
                  {radioChoicesExtra && radioChoicesExtra[rc.value] ? radioChoicesExtra[rc.value] : undefined}
                </div>
              )
            })}
          </div>
          {haveError
            ? errors.map(e => {
                let str = e
                if (e === '"" is not a valid choice.' || e === '"-1" is not a valid choice.') {
                  str = 'A choice must be selected.'
                } else if (e.includes('is not a valid choice')) {
                  str = 'Invalid choice'
                }
                return (
                  <div key={e} className="invalid-feedback d-block" style={{ marginTop: '-0.7rem' }}>
                    <small>{str}</small>
                  </div>
                )
              })
            : null}
        </div>
      )
    }
    if (choices !== undefined) {
      // TODO type of search ignore value!
      // TODO searchable hint?
      return (
        <div className={`form-group ${className || ''}`}>
          {fieldName ? <label className={labelClassName}>{fieldName || value.charAt(0).toUpperCase() + value.slice(1)}</label> : null}
          <div className={`input-group ${errors ? 'is-invalid' : ''}`}>
            <Select value={choices.find(c => c.value === value)} onChange={v => onChange(v.value)} options={choices} className="react-select-container" classNamePrefix="react-select" {...selectProps} />
            {haveError
              ? errors.map(e => {
                  let str = e
                  if (e === '"" is not a valid choice.' || e === '"-1" is not a valid choice.') {
                    str = 'A choice must be selected.'
                  } else if (e.includes('is not a valid choice')) {
                    str = 'Invalid choice'
                  }
                  return (
                    <div key={e} className="invalid-feedback select-invalid-feedback">
                      <small>{str}</small>
                    </div>
                  )
                })
              : null}
          </div>
        </div>
      )
    }
    return (
      <div className={`form-group ${className || ''}`}>
        {fieldName ? <label className={labelClassName}>{fieldName || value.charAt(0).toUpperCase() + value.slice(1)}</label> : null}
        {textarea === true ? (
          <textarea
            ref={this.input}
            value={value}
            onChange={e => {
              onChange(e.currentTarget.value)
            }}
            rows={3}
            className={`form-control ${errors ? 'is-invalid' : ''}`}
          />
        ) : (
          <div className={`input-group ${errors ? 'is-invalid' : ''}`}>
            <input
              ref={this.input}
              type={type || 'text'}
              value={value}
              onChange={e => {
                onChange(e.currentTarget.value)
              }}
              placeholder={placeholder}
              className={`form-control form-control-sm ${errors ? 'is-invalid' : ''}`}
              accept={accept}
              {...inputProps}
            />
            {inputAppend !== undefined ? (
              <div className="input-group-append">
                <span className="input-group-text">{inputAppend}</span>
              </div>
            ) : null}
            {haveError
              ? errors.map(e => (
                  <div key={e} className="invalid-feedback">
                    <small>{e}</small>
                  </div>
                ))
              : null}
          </div>
        )}
      </div>
    )
  }
}

export default FormInput
