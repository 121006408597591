import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import StudentApp from '../pages/studentApp'
import TuitionAssistance from '../pages/tuitionAssistance'
import ThankYou from '../pages/thankyou'
import NotFound from '../pages/NotFound'
import ControlPanel from '../pages/ControlPanel'

const routes = (
  <BrowserRouter>
    <Switch>
      {/* <Route exact path="/application" render={props => <StudentApp {...props} branch="Bangkok" />} /> */}
      <Route exact path="/ta" render={props => <TuitionAssistance {...props} branch="Bangkok" />} />
      <Route exact path="/application-udon" render={props => <StudentApp {...props} branch="Udon" />} />
      <Route exact path="/ta-udon" render={props => <TuitionAssistance {...props} branch="Udon" />} />
      <Route exact path="/submitted" component={ThankYou} />
      <Route exact path="/control-panel" component={ControlPanel} />
      <Route path="*" component={NotFound} status={404} />
    </Switch>
  </BrowserRouter>
)

export default routes
