import React, { Component } from 'react'
import Navbar from '../navbar'

class Layout extends Component {
  // TODO get 70px from navbar height
  render() {
    const { title, branch } = this.props
    return (
      <div className="bg-light">
        <Navbar title={title} branch={branch} />
        <div className="container py-3">{this.props.children}</div>
      </div>
    )
  }
}

export default Layout
