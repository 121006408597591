import React, { Component } from 'react'
import { withContext } from '../../contexts/withContext'
import { toast } from 'react-toastify'

class NotFound extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ta_enabled: true,
      admission_enabled: true,
      fetchedConfig: false,
      id_token: '',
    }
  }

  render() {
    const { ta_enabled, admission_enabled, fetchedConfig, id_token } = this.state
    if (!fetchedConfig) {
      return <div>Fetching config</div>
    }
    return (
      <div className="container my-5">
        <div className="mb-1">Application enabled: {admission_enabled ? <b style={{ color: 'green' }}>YES</b> : <b style={{ color: 'red' }}>NO</b>}</div>
        <button
          onClick={async () => {
            const res = await this.props.context.fetch('put', `/api/config`, { admission_enabled: !admission_enabled, id_token: id_token })
            if (res.success) {
              window.location.reload(true)
            } else {
              alert(`Unable to set config: ${JSON.stringify(res.data)}`)
            }
          }}
          className="mb-3"
        >
          {admission_enabled ? 'Disable' : 'Enable'} application
        </button>
        <div className="mb-1">TA enabled: {ta_enabled ? <b style={{ color: 'green' }}>YES</b> : <b style={{ color: 'red' }}>NO</b>}</div>
        <button
          onClick={async () => {
            const res = await this.props.context.fetch('put', `/api/config`, { ta_enabled: !ta_enabled, id_token: id_token })
            if (res.success) {
              window.location.reload(true)
            } else {
              alert(`Unable to set config: ${JSON.stringify(res.data)}`)
            }
          }}
          className=""
        >
          {admission_enabled ? 'Disable' : 'Enable'} TA
        </button>
        <div
          id="g-signin2"
          className="mt-5"
          data-redirecturi="http://localhost:3000/control-panel"
          // data-onsuccess={googleUser => {
          //   console.log(googleUser)
          // }}
        />
      </div>
    )
  }

  async componentDidMount() {
    console.log('didmount')
    const meta = document.createElement('meta')
    meta.name = 'google-signin-client_id'
    meta.content = '318619368750-kba79g1mk1d7ppmem90931gqem7t1qft.apps.googleusercontent.com'
    document.body.appendChild(meta)

    const script = document.createElement('script')
    script.src = `https://apis.google.com/js/platform.js?rnd=${Math.random().toString()}`
    script.async = true
    script.defer = true
    script.onload = async () => {
      console.log('onload')

      // const authInstance = await window.gapi.auth2.getAuthInstance()
      // console.log('authInstance', authInstance)
      window.gapi.signin2.render('g-signin2', {
        // scope: 'profile email',
        // scope: 'https://www.googleapis.com/auth/plus.login',
        // width: 200,
        // height: 50,
        // longtitle: true,
        // theme: 'dark',

        onsuccess: async googleUser => {
          // console.log('onsuccess')
          console.log(googleUser)
          if (googleUser.uc) {
            this.setState({ id_token: googleUser.uc.id_token })
          } else if (googleUser.tc) {
            this.setState({ id_token: googleUser.tc.id_token })
          } else {
            alert('token undefined')
          }
          // document.getElementById('g-signin2').innerText = 'Signed in as ' + googleUser.getBasicProfile().getName()
        },
      })
    }
    document.body.appendChild(script)

    const res = await this.props.context.fetch('get', `/api/config`)
    if (res.success) {
      console.log(res.data)
      this.setState({
        fetchedConfig: true,
        ta_enabled: res.data.ta_enabled,
        admission_enabled: res.data.admission_enabled,
      })
    } else {
      alert(`Unable to fetch config: ${res.data}`)
    }
  }
}

export default withContext(NotFound)
