/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/sort-comp */
import _ from 'lodash'
// import moment from 'moment'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { toast } from 'react-toastify'
import { isNumber } from 'util'
import FormInput from '../../components/formInput'
import Layout from '../../components/layout'
import { CONST } from '../../constants/index'
import { withContext } from '../../contexts/withContext'
import { addBlankChoices } from '../../utils/utilFunctions'

const uuidv4 = require('uuid/v4')

class StudentApp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ta_enabled: false,
      admission_enabled: false,
      fetchedConfig: false,

      localKey: `studentAppFields${props.branch === 'Bangkok' ? '' : props.branch}`,
      fields: {
        branch: props.branch,
        school_year: '',
        signature: '',
        signature_date: '',
        accepted_pdpa: false,
        student_info: {
          first_name: '',
          last_name: '',
          nickname: '',
          date_of_birth: '',
          sex: '',
          applying_for_grade: '',
          term: '',
          nationality_on_passport: '',
          type_of_visa: '',
          home_address: '',
          phone: '',
        },
        sibling_info: [
          {
            name: '',
            date_of_birth: '',
            school: '',
            grade: '',
          },
        ],
        guardian_info: [
          {
            order: 1,
            guardian_type: -1,
            full_name: '',
            nationality_on_passport: -1,
            employer: '',
            position: '',
            office_address: '',
            work_phone: '',
            email: '',
            mobile_phone: '',
            country_code: -1,
            did_graduate_from_ics: -1,
            graduation_year: '',
          },
          {
            order: 2,
            guardian_type: -1,
            full_name: '',
            nationality_on_passport: -1,
            employer: '',
            position: '',
            office_address: '',
            work_phone: '',
            email: '',
            mobile_phone: '',
            country_code: -1,
            did_graduate_from_ics: -1,
            graduation_year: '',
          },
        ],
        edu_history: {
          previous_school_attended: '',
          dates: '',
          program: '',
          previously_enrolled_ics: false,
          previously_enrolled_ics_grades: -1,
          previously_applied_ics: false,
          previously_applied_ics_grades: -1,
          first_language: '',
          second_language: '',
          language_spoken_at_home: '',
          english_proficiency: '',
          has_withdrawn_n: -1,
          has_withdrawn_explain: '',
          has_retained_n: -1,
          has_retained_explain: '',
          has_learning_disability_n: -1,
          has_learning_disability_explain: '',
          has_behavior_drug_n: -1,
          has_behavior_drug_explain: '',
          has_regular_medication_n: -1,
          has_regular_medication_conditions: '',
          has_medical_conditions_n: -1,
          has_medical_conditions_explain: '',
        },
        guardian_generic_info: {
          marital_status: '',
          legal_custody: '',
          student_lives_with: '',
          mailing_address: '',
          financially_responsible: '',
          financially_responsible_other: '',
          religion: '',
          religion_other: '',
          denomination: '',
          other_information: '',
          ics_source: '',
          ics_source_other: '',
        },
        admission_attachment: [],
      },
      errors: {},
    }
  }

  async componentDidMount() {
    const res = await this.props.context.fetch('get', `/api/config`)
    if (res.success) {
      this.setState({
        ta_enabled: res.data.ta_enabled,
        admission_enabled: res.data.admission_enabled,
        fetchedConfig: true,
      })
    } else {
      alert(`Unable to fetch config: ${res.data}`)
    }

    // TODO move this into layout?
    document.title = 'Student Application'
    const storedFields = localStorage.getItem(this.state.localKey)
    const currentFieldsObj = this.state.fields
    // await new Promise(resolve => setTimeout(resolve, 2000))
    if (storedFields) {
      try {
        const storedFieldsObj = JSON.parse(storedFields)
        // remove the default 1 sibling info if there's saved data
        // this is for in case user erased all sibling rows, merging the default field with stored field won't unintentionally create new sibling row
        currentFieldsObj.sibling_info = []
        this.setState({ fields: _.merge(currentFieldsObj, storedFieldsObj) })
      } catch {
        // TODO delete localstorage when error
      }
    }
  }

  submit = async () => {
    // submit only needs ID of file
    const fields = _.cloneDeep(this.state.fields)
    fields.admission_attachment = fields.admission_attachment.filter(a => a.id !== undefined).map(a => a.id)
    fields.sibling_info = fields.sibling_info.filter(c => c.name !== '')
    // clean non-revelant information
    try {
      if (fields.guardian_generic_info.religion !== '3') fields.guardian_generic_info.denomination = ''
    } catch {}
    try {
      if (fields.guardian_generic_info.religion !== '4') fields.guardian_generic_info.religion_other = ''
    } catch {}
    try {
      if (fields.edu_history.previously_enrolled_ics !== true) fields.edu_history.previously_enrolled_ics_grades = -1
    } catch {}
    try {
      if (fields.edu_history.previously_applied_ics !== true) fields.edu_history.previously_applied_ics_grades = -1
    } catch {}
    try {
      if (fields.guardian_generic_info !== '4') fields.ics_source_other = ''
    } catch {}
    try {
      if (fields.guardian_generic_info.financially_responsible !== '2') fields.guardian_generic_info.financially_responsible_other = ''
    } catch {}

    try {
      if (fields.edu_history.has_withdrawn_n !== '1') fields.edu_history.has_withdrawn_explain = ''
    } catch {}
    try {
      if (fields.edu_history.has_retained_n !== '1') fields.edu_history.has_retained_explain = ''
    } catch {}
    try {
      if (fields.edu_history.has_learning_disability_n !== '1') fields.edu_history.has_learning_disability_explain = ''
    } catch {}
    try {
      if (fields.edu_history.has_behavior_drug_n !== '1') fields.edu_history.has_behavior_drug_explain = ''
    } catch {}
    try {
      if (fields.edu_history.has_regular_medication_n !== '1') fields.edu_history.has_regular_medication_conditions = ''
    } catch {}
    try {
      if (fields.edu_history.has_medical_conditions_n !== '1') fields.edu_history.has_medical_conditions_explain = ''
    } catch {}

    try {
      fields.guardian_info.forEach(gi => {
        if (gi.did_graduate_from_ics !== '1') {
          gi.graduation_year = ''
        }
      })
    } catch {}

    // TODO TEST THIS INFO NOT DISAPPEAR!!

    // TODO fix sibling / ta errors row idx mismatch
    const res = await this.props.context.fetch('post', `/api/admissions/`, fields)
    if (res.success) {
      localStorage.removeItem(this.state.localKey)
      this.props.history.push(`/submitted?b=${this.props.branch}&origin=application`)
    } else {
      toast.error('Some fields are not valid. Please fix all errors and resubmit')
      this.setState({ errors: res.data })
    }
  }

  setStateThenSaveLocal = state => {
    this.setState(state, this.saveFieldsToLocalStorage)
  }

  saveFieldsToLocalStorage = _.debounce(() => {
    localStorage.setItem(this.state.localKey, JSON.stringify(this.state.fields))
  }, 1000)

  handleFileUpload = async (file, filetype) => {
    const formData = new FormData()
    formData.append('url', file)
    formData.append('filename', file.name)
    formData.append('filetype', filetype)

    const localId = uuidv4()
    const attachments = _.cloneDeep(this.state.fields.admission_attachment)
    attachments.push({ localId, name: file.name, uploaded: false, filetype })
    this.setStateThenSaveLocal({
      fields: { ...this.state.fields, admission_attachment: attachments },
    })

    // config for rendering upload percent
    const config = {
      onUploadProgress: progressEvent => {
        let attachments = _.cloneDeep(this.state.fields.admission_attachment)
        attachments = attachments.map(a => {
          if (a.localId === localId) {
            return { ...a, progress: Math.round((progressEvent.loaded * 100) / progressEvent.total) }
          }
          return a
        })
        this.setStateThenSaveLocal({
          fields: { ...this.state.fields, admission_attachment: attachments },
        })
      },
    }

    const res = await this.props.context.fetch('post', '/api/admission-attachment/', formData, undefined, config)
    if (res.success) {
      let attachments = _.cloneDeep(this.state.fields.admission_attachment)
      attachments = attachments.map(a => {
        if (a.localId === localId) {
          console.log(res.data.url)
          return { ...a, id: res.data.id, url: res.data.url.replace('http://127.0.0.1:8000', process.env.REACT_APP_SERVER_ADDR), uploaded: true }
        }
        return a
      })
      this.setStateThenSaveLocal({
        fields: { ...this.state.fields, admission_attachment: attachments },
      })
    } else {
      let errorMsg = ''
      if (res.data !== undefined && res.data.url !== undefined) {
        errorMsg = res.data.url.join('\n')
      } else if (res.data === undefined) {
        errorMsg = 'Please check your connection and try again'
      } else {
        errorMsg = `Unknown error - ${JSON.stringify(res.data)}`
      }
      toast.error(`File upload error: ${errorMsg}`)
      const newAttachment = _.cloneDeep(this.state.fields.admission_attachment.filter(a => a.localId !== localId))
      this.setStateThenSaveLocal({
        fields: { ...this.state.fields, admission_attachment: newAttachment },
      })
    }
  }

  renderDropzone = (fileTypeName, fieldName) => {
    return (
      <Dropzone
        onDrop={acceptedFiles => {
          acceptedFiles.map(file => this.handleFileUpload(file, fileTypeName))
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className="dropzone d-flex align-items-center justify-content-center">
              <input {...getInputProps()} />
              <div className="text-center">
                <div>{fieldName}</div>
                <div className="drop-zone-hint">
                  <div>Drop files here or click to select files</div>
                  <div>Max file size: 10MB</div>
                </div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    )
  }

  renderDroppedFiles = filetype => {
    const { fields } = this.state
    return (
      <div className="py-2">
        {fields.admission_attachment
          .filter(a => a.filetype === filetype)
          .map((attachment, idx) => {
            const { progress, uploaded, name, localId } = attachment
            let uploadStatus = ''
            if (uploaded === true) {
              uploadStatus = 'uploaded'
            } else if (isNumber(progress)) {
              uploadStatus = `uploading ${progress}%`
            }
            //TODO DELETE SERVERSIDE
            return (
              <div key={localId} className="dropped-files">
                <a target="_newtab" href={attachment.url}>{`${name} - ${uploadStatus}`}</a>
                <span
                  className="a ml-1 deletespan"
                  onClick={() => {
                    const newAttachment = _.cloneDeep(fields.admission_attachment.filter(a => a.localId !== localId))
                    this.setStateThenSaveLocal({
                      fields: { ...fields, admission_attachment: newAttachment },
                    })
                  }}
                >
                  (Delete)
                </span>
              </div>
            )
          })}
      </div>
    )
  }

  renderFields = (objectKeyList, args, setExtraFields) => {
    const { fields, errors } = this.state
    let fValue = fields
    let eValue = errors
    objectKeyList.forEach(key => {
      fValue = (fValue && fValue[key]) || ''
      eValue = (eValue && eValue[key]) || undefined
    })
    return (
      <FormInput
        {...args}
        value={fValue}
        errors={eValue}
        onChange={v => {
          const newFields = _.cloneDeep(this.state.fields)
          let ac = newFields
          objectKeyList.forEach((key, idx) => {
            if (idx !== objectKeyList.length - 1) {
              ac = ac[key]
            } else {
              ac[key] = v
            }
          })
          if (setExtraFields !== undefined) {
            this.setStateThenSaveLocal({ fields: setExtraFields(newFields, v) })
          } else {
            this.setStateThenSaveLocal({ fields: newFields })
          }
        }}
      />
    )
  }

  render() {
    const { fields, errors, admission_enabled, fetchedConfig } = this.state
    const { renderFields } = this

    if (!fetchedConfig) {
      return <Layout title={`Student Application - ${this.props.branch}`} branch={this.props.branch} />
    }
    if (!admission_enabled) {
      return (
        <Layout title={`Student Application - ${this.props.branch}`} branch={this.props.branch}>
          <h1 className="my-5 text-center" style={{ border: 'none' }}>
            Student application is closed
          </h1>
        </Layout>
      )
    }

    return (
      <Layout title={`Student Application - ${this.props.branch}`} branch={this.props.branch}>
        <form>
          <div className="row">
            <div className="col-12 col-md-6">{renderFields(['school_year'], { fieldName: 'Apply for school year: ', choices: CONST.school_year.filter(sy => sy.value > 5 && sy.value < 8) })}</div>
          </div>
          <hr />
          <h1>Student Information</h1>
          <div className="row">
            <div className="col-12 col-md-6">{renderFields(['student_info', 'last_name'], { fieldName: 'Last Name' })}</div>
            <div className="col-12 col-md-6">{renderFields(['student_info', 'first_name'], { fieldName: 'First Name' })}</div>
            <div className="col-12 col-md-6">{renderFields(['student_info', 'nickname'], { fieldName: 'Nick Name/Preferred Name' })}</div>
            <div className="col-12 col-md-6">{renderFields(['student_info', 'date_of_birth'], { fieldName: 'Date of birth, please click the exact date', DP: true })}</div>
            <div className="col-12 col-md-6">{renderFields(['student_info', 'sex'], { fieldName: 'Sex', choices: CONST.sex })}</div>
            <div className="col-12 col-md-6">{renderFields(['student_info', 'applying_for_grade'], { fieldName: 'Applying for grade', choices: CONST.grades })}</div>
            <div className="col-12 col-md-6">{renderFields(['student_info', 'term'], { fieldName: 'Term', choices: CONST.term })}</div>
            <div className="col-12 col-md-6">{renderFields(['student_info', 'nationality_on_passport'], { fieldName: 'Country on Passport', choices: CONST.country })}</div>
            <div className="col-12 col-md-6">{renderFields(['student_info', 'type_of_visa'], { fieldName: 'Type of Visa', choices: addBlankChoices(CONST.type_of_visa) })}</div>
            <div className="col-12 col-md-6">{renderFields(['student_info', 'phone'], { fieldName: 'Thai phone number', placeholder: '0xxxxxxxxxx' })}</div>
            <div className="col-12">{renderFields(['student_info', 'home_address'], { fieldName: 'Home Address', textarea: true })}</div>
          </div>
          <h3 className="my-3">
            <strong>Sibling Information</strong> (Please list all siblings under 18 years of age)
          </h3>
          <div>
            <div className="d-none d-md-flex row">
              <div className="col-3">Name</div>
              <div className="col-2">Date of Birth</div>
              <div className="col-4">Last school attended</div>
              <div className="col-2">Grade</div>
            </div>
            {fields.sibling_info.map((p, idx) => (
              <div key={idx} className="row">
                <div className="col-12 col-md-3">{renderFields(['sibling_info', idx, 'name'], { fieldName: 'Name', labelClassName: 'd-md-none' })}</div>
                <div className="col-12 col-md-2">{renderFields(['sibling_info', idx, 'date_of_birth'], { DP: true, fieldName: 'Date of Birth', labelClassName: 'd-md-none' })}</div>
                <div className="col-12 col-md-4">{renderFields(['sibling_info', idx, 'school'], { fieldName: 'Last School Attended', labelClassName: 'd-md-none' })}</div>
                <div className="col-12 col-md-2">{renderFields(['sibling_info', idx, 'grade'], { fieldName: 'Grade', labelClassName: 'd-md-none' })}</div>
                <div className="col-12 col-md-1">
                  {idx !== 0 ? (
                    <button
                      type="button"
                      className="btn btn-info btn-form"
                      onClick={() => {
                        const newSiblingInfo = _.cloneDeep(fields.sibling_info)
                        newSiblingInfo.splice(idx, 1)
                        this.setStateThenSaveLocal({
                          fields: { ...fields, sibling_info: newSiblingInfo },
                        })
                      }}
                    >
                      Delete
                    </button>
                  ) : null}
                  <hr className="d-md-none" />
                </div>
              </div>
            ))}
            {fields.sibling_info.length < 6 ? (
              <button
                type="button"
                className="btn btn-info btn-form"
                onClick={() => {
                  const newSiblingInfo = _.cloneDeep(fields.sibling_info)
                  newSiblingInfo.push({
                    name: '',
                    date_of_birth: '',
                    school: '',
                    grade: '',
                  })
                  this.setStateThenSaveLocal({
                    fields: { ...fields, sibling_info: newSiblingInfo },
                  })
                }}
              >
                Add
              </button>
            ) : null}
          </div>
          <hr />
          <h1 className="mt-3">Educational History</h1>
          <div className="row">
            <div className="col-12 col-md-6">{renderFields(['edu_history', 'previous_school_attended'], { fieldName: 'Last school attended' })}</div>
            <div className="col-12 col-md-6">{renderFields(['edu_history', 'dates'], { fieldName: 'Year attended', placeholder: '20xx-20xx' })}</div>
            <div className="col-12">{renderFields(['edu_history', 'program'], { fieldName: 'Program', radioChoices: addBlankChoices(CONST.program, 'N/A'), className: 'form-check-inline' })}</div>
            <div className="col-12 col-md-6 d-flex align-items-center mb-2 mb-sm-0">{renderFields(['edu_history', 'previously_enrolled_ics'], { fieldName: 'Check the box if the student was previously enrolled at ICS', check: true })}</div>
            <div className="col-12 col-md-6">{renderFields(['edu_history', 'previously_enrolled_ics_grades'], { fieldName: 'Last grade attended at ICS', choices: addBlankChoices(CONST.grades), selectProps: { isDisabled: fields.edu_history.previously_enrolled_ics === false } })}</div>
            <div className="col-12 col-md-6 d-flex align-items-center mb-2 mb-sm-0">{renderFields(['edu_history', 'previously_applied_ics'], { fieldName: 'Check the box if the student has previously applied for admission to ICS', check: true })}</div>
            <div className="col-12 col-md-6">{renderFields(['edu_history', 'previously_applied_ics_grades'], { fieldName: 'Previous grade applied for ICS', choices: addBlankChoices(CONST.grades), selectProps: { isDisabled: fields.edu_history.previously_applied_ics === false } })}</div>
            <div className="col-12 col-md-6">{renderFields(['edu_history', 'first_language'], { fieldName: "Student's first language" })}</div>
            <div className="col-12 col-md-6">{renderFields(['edu_history', 'second_language'], { fieldName: 'Second Language' })}</div>
            <div className="col-12 col-md-6">{renderFields(['edu_history', 'language_spoken_at_home'], { fieldName: 'Language Spoken at Home' })}</div>
            <div className="col-12 col-md-6">{renderFields(['edu_history', 'english_proficiency'], { fieldName: 'English proficiency of student', className: 'form-check-inline', radioChoices: CONST.english_proficiency })}</div>

            <div className="col-12">{renderFields(['edu_history', 'has_withdrawn_n'], { fieldName: 'Has this student ever been withdrawn, suspended or expelled from any school for any reason?', radioChoices: CONST.yes_no, className: 'form-check-inline' })}</div>
            {fields.edu_history.has_withdrawn_n === '1' ? <div className="col-12 mb-3 mt-n1">{renderFields(['edu_history', 'has_withdrawn_explain'], { fieldName: 'Please explain' })}</div> : null}

            <div className="col-12">{renderFields(['edu_history', 'has_retained_n'], { fieldName: 'Has this student ever been retained in any grade?', radioChoices: CONST.yes_no, className: 'form-check-inline' })}</div>
            {fields.edu_history.has_retained_n === '1' ? <div className="col-12 mb-3 mt-n1">{renderFields(['edu_history', 'has_retained_explain'], { fieldName: 'Please explain' })}</div> : null}

            <div className="col-12">{renderFields(['edu_history', 'has_learning_disability_n'], { fieldName: 'Has this student ever been diagnosed as having a learning disability or received treatment or tutoring for an ongoing learning problem?', radioChoices: CONST.yes_no, className: 'form-check-inline' })}</div>
            {fields.edu_history.has_learning_disability_n === '1' ? <div className="col-12 mb-3 mt-n1">{renderFields(['edu_history', 'has_learning_disability_explain'], { fieldName: 'Please explain and attach the report along with school records below.' })}</div> : null}

            <div className="col-12">{renderFields(['edu_history', 'has_behavior_drug_n'], { fieldName: 'Has this student ever shown evidence of a behavior, emotional, or drug problem and/or received treatment from a physician, psychologist or counselor for a behavior, emotional or drug related problem?', radioChoices: CONST.yes_no, className: 'form-check-inline' })}</div>
            {fields.edu_history.has_behavior_drug_n === '1' ? <div className="col-12 mb-3 mt-n1">{renderFields(['edu_history', 'has_behavior_drug_explain'], { fieldName: "Please explain and include the physician or counselor's report along with the school records" })}</div> : null}

            <div className="col-12">{renderFields(['edu_history', 'has_regular_medication_n'], { fieldName: 'Is this student taking any medication on a regular basis?', radioChoices: CONST.yes_no, className: 'form-check-inline' })}</div>
            {fields.edu_history.has_regular_medication_n === '1' ? <div className="col-12 mb-3 mt-n1">{renderFields(['edu_history', 'has_regular_medication_conditions'], { fieldName: 'Please explain' })}</div> : null}

            <div className="col-12">{renderFields(['edu_history', 'has_medical_conditions_n'], { fieldName: 'Are there any medical conditions, illnesses or allergies the school should be aware of in order to best care for and support the student?', radioChoices: CONST.yes_no, className: 'form-check-inline' })}</div>
            {fields.edu_history.has_medical_conditions_n === '1' ? <div className="col-12 mb-3 mt-n1">{renderFields(['edu_history', 'has_medical_conditions_explain'], { fieldName: 'Please explain' })}</div> : null}
          </div>
          <hr />
          <h1 className="mb-4">Parent and Guardian Information</h1>
          {fields.guardian_info.map((g, idx) => (
            <div key={idx} className="row mt-2">
              <div className="col-12">{renderFields(['guardian_info', idx, 'guardian_type'], { radioChoices: CONST.guardian_type, className: 'form-check-inline' })}</div>
              <div className="col-12 col-md-6">{renderFields(['guardian_info', idx, 'full_name'], { fieldName: 'Full Name' })}</div>
              <div className="col-12 col-md-6">{renderFields(['guardian_info', idx, 'nationality_on_passport'], { fieldName: 'Country on Passport', choices: CONST.country })}</div>
              <div className="col-12 col-md-6">{renderFields(['guardian_info', idx, 'employer'], { fieldName: 'Employer' })}</div>
              <div className="col-12 col-md-6">{renderFields(['guardian_info', idx, 'position'], { fieldName: 'Position' })}</div>
              <div className="col-12">{renderFields(['guardian_info', idx, 'office_address'], { fieldName: 'Office Address' })}</div>
              <div className="col-12 col-md-6">{renderFields(['guardian_info', idx, 'work_phone'], { fieldName: 'Work Phone' })}</div>
              <div className="col-12 col-md-6">{renderFields(['guardian_info', idx, 'email'], { fieldName: 'Email address for all school communication' })}</div>
              <div className="col-12 col-sm-6 col-lg-3">{renderFields(['guardian_info', idx, 'country_code'], { fieldName: 'Country Code', choices: CONST.country_code })}</div>
              <div className="col-12 col-sm-6 col-lg-3">{renderFields(['guardian_info', idx, 'mobile_phone'], { fieldName: 'Mobile Phone - no spaces' })}</div>
              <div className="col-12 col-sm-6 col-lg-3 mb-2 mb-sm-0">{renderFields(['guardian_info', idx, 'did_graduate_from_ics'], { fieldName: 'Did the parent graduate from ICS?', radioChoices: CONST.yes_no, className: 'form-check-inline' })}</div>
              <div className="col-12 col-sm-6 col-lg-3">
                {renderFields(['guardian_info', idx, 'graduation_year'], {
                  fieldName: 'Graduation year',
                  inputProps: {
                    disabled: (() => {
                      try {
                        return fields.guardian_info[idx].did_graduate_from_ics !== '1'
                      } catch {}
                      return true
                    })(),
                  },
                })}
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
          ))}
          <div className="row">
            <div className="col-12">
              <strong>Marital status:</strong>
            </div>
            <div className="col-12">{renderFields(['guardian_generic_info', 'marital_status'], { radioChoices: CONST.marital_status, className: 'form-check-inline' })}</div>
            <b className="col-12">If parents are separated, divorced or remarried, please provide the following information:</b>
            <div className="d-flex flex-column flex-md-row pl-5">
              <span className="mr-3">Who has legal custody? (Official documentation is required) </span>
              {renderFields(['guardian_generic_info', 'legal_custody'], { radioChoices: addBlankChoices(CONST.legal_custody, 'N/A'), className: 'form-check-inline' })}
            </div>
            <div className="d-flex flex-column flex-md-row pl-5">
              <span className="mr-3">Student lives with: </span>
              {renderFields(['guardian_generic_info', 'student_lives_with'], { radioChoices: addBlankChoices(CONST.student_lives_with, 'N/A'), className: 'form-check-inline' })}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <strong>Mailing address:</strong>
            </div>
            <div className="col-12">{renderFields(['guardian_generic_info', 'mailing_address'], { fieldName: 'Please specify if different from home address' })}</div>
          </div>
          <div className="row">
            <div className="col-12">
              <strong>Financial Information:</strong>
            </div>
            <div className="col-12">Who will be financially responsible for the tuition and fees?</div>
            <div className="col-12">
              {renderFields(['guardian_generic_info', 'financially_responsible'], {
                className: 'd-flex align-items-center',
                radioChoices: CONST.financially_responsible,
                radioChoicesExtra: {
                  2: (
                    <div className="mx-2 d-flex align-items-center radio-form-inline">
                      <div className="col-12 col-md px-1 my-2">
                        {renderFields(['guardian_generic_info', 'financially_responsible_other'], {
                          fieldName: 'Specify Name of Person / Business / Organization',
                          inputProps: {
                            disabled: (() => {
                              try {
                                return fields.guardian_generic_info.financially_responsible !== '2'
                              } catch {}
                              return true
                            })(),
                          },
                        })}
                      </div>
                    </div>
                  ),
                },
              })}
            </div>
            {/* Cannot be blank only if other is selected */}
          </div>
          <div className="row">
            <div className="col-12">
              <strong>Religious Affiliation:</strong>
            </div>
            <div className="col-12">
              {renderFields(['guardian_generic_info', 'religion'], {
                className: 'd-flex align-items-center',
                radioChoices: CONST.religion,
                radioChoicesExtra: {
                  3: (
                    <div className="mx-2 d-flex align-items-center radio-form-inline">
                      <div className="col-12 col-md px-1 my-2">
                        {renderFields(['guardian_generic_info', 'denomination'], {
                          fieldName: 'Denomination:',
                          inputProps: {
                            disabled: (() => {
                              try {
                                return fields.guardian_generic_info.religion !== '3'
                              } catch {}
                              return true
                            })(),
                          },
                        })}
                      </div>
                    </div>
                  ),
                  4: (
                    <div className="d-flex align-items-center radio-form-inline">
                      :
                      {renderFields(['guardian_generic_info', 'religion_other'], {
                        inputProps: {
                          disabled: (() => {
                            try {
                              return fields.guardian_generic_info.religion !== '4'
                            } catch {}
                            return true
                          })(),
                        },
                      })}
                    </div>
                  ),
                },
              })}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <strong>Other information you would like us to know about the student such as special hobbies or interests</strong>
            </div>
            <div className="col-12">{renderFields(['guardian_generic_info', 'other_information'])}</div>
          </div>
          <div className="row">
            <div className="col-12">
              <strong> How did you hear about ICS?</strong>
            </div>
            <div className="col-12">
              {renderFields(['guardian_generic_info', 'ics_source'], {
                className: 'd-flex align-items-center',
                radioChoices: CONST.ics_source,
                radioChoicesExtra: {
                  4: (
                    <div className="d-flex align-items-center radio-form-inline">
                      :
                      {renderFields(['guardian_generic_info', 'ics_source_other'], {
                        inputProps: {
                          disabled: (() => {
                            try {
                              return fields.guardian_generic_info.ics_source !== '4'
                            } catch {}
                            return true
                          })(),
                        },
                      })}
                    </div>
                  ),
                },
              })}
            </div>
          </div>
          <h1 className="my-3">Terms of Acceptance</h1>
          <p>I hereby acknowledge, understand and agree to permit my child to receive instruction in biblically based ethics and values while they are enrolled at ICS. Furthermore, I agree to cooperate with ICS personnel and support the school rules and guidelines as outlined in the Parent/Student Handbook.</p>
          <p>
            I understand that my child is expected to follow the school rules during school activities and all school sponsored trips. I agree to allow my child to participate in all school activities including school-sponsored trips away from the school. I release the teacher and school from any claims due to loss of the child's belongings and/or injury to the child that is beyond the reasonable control of the teacher and the school. I further authorize the school to secure necessary emergency
            medical attention for my child in the event of any injury at school or on a school-sponsored trip.
          </p>
          <p>I understand that my child's photographs may be in publications for the school promotional programs.</p>
          <p>
            I understand that all tuition and fees for the school year are to be paid in full by cashiers check or direct bank deposit before my child is allowed to attend classes. See the tuition and fee schedule for current information. <b>I understand and accept that the Application fee, Registration fee, and the Annual Capital fee are non-refundable under any circumstance.</b>
          </p>
          <p>We certify that the information provided in this application is accurate to the best of our knowledge. We authorize ICS to request transcripts and verification of the information contained in this application. Failure to divulge full information or records in these areas may lead to dismissal of the student from International Community School.</p>
          <p>I have read and understand the terms of acceptance</p>
          <div className="row">
            <div className="col-12 col-md-6">
              <b>Signature - by entering your full name below you are verifying the information on the application is true and correct. This acts in lieu of a physical signature on your application.</b>
              {renderFields(['signature'])}
            </div>
            <div className="col-12 col-md-6 d-flex flex-column justify-content-between">
              <b>Date</b>
              {renderFields(['signature_date'], { DP: true })}
            </div>
          </div>
          <h1 className="my-3">Personal Data Protection Policy</h1>
          <div className="px-3 py-2 pdpa" style={{ maxHeight: '70vh', overflowY: 'auto', boxShadow: 'inset 0px 0px 3px 0px rgba(0,0,0,0.75)' }}>
            <h2>ICS Policy on the Personal Data Protection Act (PDPA) </h2>
            <h5>Introduction </h5>
            <p> The International Community School (ICS) will, in the process of its operations; collect, process, disclose and use personal data in compliance with the requirements of Thailand's Personal Data Protection Act (PDPA) and according to the guidelines in this policy.</p>
            <h5>The Basics </h5>
            <p>“Personal data” includes any data pertaining to a living natural person that enables the identification of that person, whether directly or indirectly. The PDPA applies to both digital and physical data, and includes information such as phone numbers, addresses, email addresses, or anything that might enable identification of the data subject—the person directly or indirectly linked to the information in question.</p>
            <h5>Types of information collected: </h5>
            <ul>
              <li>Personal details and demographic information (name, contact details, gender, nationality, medical information, passports, national ID, etc. (for students and families as needed) </li>
              <li>Financial information (only when applying for tuition assistance at ICS) </li>
              <li>Normal work history, education history, references, contact and family information of employees </li>
              <li>Responses to surveys </li>
              <li>Text, images, and film from school-related activities </li>
              <li>Inquiries and comments via the ICS website and social media </li>
            </ul>
            <h5>Purpose of data collection: </h5>
            <ul>
              <li>Maintain regular communication channels for communicating student progress and disseminating information regarding school programs </li>
              <li>Administration and intervention in the event of medical or pastoral counseling requirements </li>
              <li>Review strategic plans, academic policies and guidelines </li>
              <li>Management of security and risk </li>
              <li>Provide education services, track progression of students and evaluate student’s suitability for a course </li>
              <li>Regulatory reporting and compliance </li>
              <li>Analysis of information to improve and develop the ICS website </li>
              <li>To ensure student health and safety </li>
              <li>To apply for and maintain legal status in Thailand </li>
              <li>To determine the need for financial assistance </li>
              <li>To make enrollment decisions or for the purpose of assigning placement in certain activities or services </li>
              <li>To supply data to other schools or universities at the request of the student or parents </li>
              <li>To respond to an inquiry via the website or other channels </li>
              <li>To establish the suitability of an employment applicant and application of benefits of the candidate, if hired </li>
            </ul>
            <h5>Confidentiality of Information </h5>
            <p> Authorized personnel within ICS and appointed data intermediaries will be able to access the information you provide to us. We only do so with your consent and will always ensure that your information is used in accordance with the terms of this privacy policy and the Thailand PDPA. Unless required or permitted to do so by law, we will not otherwise share, sell or distribute any of the information you provide to us without your consent.</p>
            <h5>How to contact us </h5>
            <p>
              The confidentiality of your personal data is important to ICS. You have our assurance that if your personal data is collected, used or disclosed for the purposes we have listed above, we will use it only in accordance with the Thailand Personal Data Protection Act (2019) requirements. If you need clarifications, corrections, access to your information, withdrawal of consent for use of your personal data, or other information regarding your personal data kept with ICS, you can
              contact the school office at: +662-338-0777 The following is more in-depth explanation of this policy and the Thailand PDPA
            </p>
            <h5>PDPA Main Roles </h5>
            <p>
              The PDPA lays out three main roles relating to the handling of others’ personal data: the data controller, the data processor, and data protection officer. The data controller is a person or entity with power to make decisions regarding collection, use, and disclosure of personal data. The data processor is a person or entity that collects, uses, or discloses personal data on behalf of, or under the instructions of, the data controller. The data controller carries significant
              liability and obligations, while the processor’s obligations and liabilities are very limited in comparison.
            </p>
            <div className="ml-3">
              <h6>Data controller’s obligations </h6>
              <p>
                Data controllers take principal responsibility for ensuring that ICS staff fulfill all their obligations for handling personal data, including collection, use, and transfer. Their first duty is to ensure that throughout these steps, the personal data remains correct, up-to-date, complete, and not misleading. In terms of security and maintenance, the data controller must implement suitable measures for preventing loss, unauthorized access, alteration, or disclosure of personal
                data. These measures must be reviewed whenever necessary, such as after the implementation of technological developments. The data must be recorded in a form, either written or electronic, that can be inspected by the data subject or an authorized party. When the storage period expires, the personal data is no longer relevant or exceeds the scope of necessity, or the consent is withdrawn. The data controller then is also responsible for seeing that the personal data is erased. At
                ICS, this person, the school superintendent, is inevitably responsible for this, but is assisted by members of the leadership team and various members of the ICS administrative staff.
              </p>
              <h6>Data processors’ obligations </h6>
              <p>
                Data processors are required to strictly comply with the controller’s lawful instructions and orders, and conversely not take action outside those instructions. The data processor must also implement suitable measures for preventing loss or unauthorized access. They must make sure suitable measures for storing personal data and preventing unauthorized access are in place. The data processor must also record processing information. This means maintaining an inventory of the
                collection, transfer, and use of personal data. At ICS, data processors are all of those who imput, access and use the data as listed above and include nurses, finance office staff, the registrar, the admissions staff, IT staff, teachers, web developers, and various other administrative staff.
              </p>
              <h6>Data Protection Officer </h6>
              <p>
                Data controllers or processors with a large amount of personal data will also have to appoint a data protection officer (DPO) to monitor and verify compliance with the PDPA by conducting compliance audits or inspections. The DPO will interact with the regulator if any issues arise. Businesses with a large retail customer base that generates a large volume of personal data will probably already have a DPO in place. The PDPA requires appointment of a DPO if the nature of the data
                controller’s activities consist of collecting, using, and disclosing personal data, or if these activities require monitoring due to the large scale of personal data (the exact scale to be set later by the Personal Data Protection Commission). At ICS we will not have a specific Data Protection Officer, rather this function is also the responsibility of the superintendent.
              </p>
            </div>

            <h5>Collecting consent </h5>
            <p>
              The collector of personal data must either have <b>consent</b> from the data subject or be covered by one of the exemptions detailed below. Consent can be given in writing or in electronic form. A request for consent must be clear and must not be deceptive or cause the data subject to misunderstand. The controller seeking consent must inform the data subject of the purpose of collection; the type of personal data being collected; relevant third parties to whom the data will be
              disclosed; and the period of retention or use. Any changes to this information will require further consent, and consent can be withdrawn at any time.{' '}
            </p>
            <p>
              Some exceptions, such as when the personal information is for educational, research, or statistics collection purposes (provided appropriate personal data protection measures are in place), or when it helps to prevent danger to a person’s life, body, or health. Also, certain contractual obligations do not require further consent. For instance, an agreement to sell goods and deliver them to various locations or email addresses would not need consent for handling each separate
              delivery address or email.
            </p>
            <h5>Data subjects’ rights </h5>
            <p>Under the PDPA, data subjects are accorded a number of rights over their personal data:</p>
            <ol>
              <li>Objection: The right to object to any collection, use, or disclosure of personal data at any time. </li>
              <li>Access: The right to ask a data controller to provide a copy of the data subject’s personal information and disclose where they obtained it. The data controller will now be obligated to disclose, upon request, how they obtained the data subject’s personal data. </li>
              <li>Erasure: The right to ask a controller to anonymize or delete personal information at any time. </li>
              <li>Data portability: The right to obtain the data in commonly used machine-readable format. This right lets a data subject, for example, ask a hospital to transfer all personal data to the subject or to another hospital. </li>
            </ol>
            <h5>ICS PDPA Adherence Statement </h5>
            <p>The below will be located at the conclusion of every form of data collection we have at ICS including but not limited to ICS Student Applications, ICS Teacher Applications, Tuition Assistance Applications, online after school enrichment sign-up forms, online camp/sport/field trip sign-up forms, etc.</p>
          </div>
          <div className="mt-3 col-12 d-flex align-items-center">
            <div className="mx-3">
              {renderFields(['accepted_pdpa'], {
                fieldName: 'By checking this box, you affirm that you have read and understand the ICS Personal Data Protection Policy and hereby agree to submit and grant access to your personal data to ICS based on the guidelines set out for collecting, use of, and retention of that information as well as the rights afforded to you by this policy and the Thailand PDPA.',
                check: true,
                pdpa: true,
              })}
            </div>
          </div>
          <h1 className="my-3">Required Documents</h1>
          {errors.admission_attachment !== undefined && Array.isArray(errors.admission_attachment) && errors.admission_attachment.join('').includes('Invalid pk') ? <div className="col-12 invalid-attachment">Uploaded attachments expired, please re-upload the attachments</div> : null}
          <div className="font-weight-bold mb-3">Please submit all the required documentation with this application. An incomplete application will not be processed. Submission of an application does not guarantee enrollment.</div>
          <hr />
          <div className="mt-2">
            <div>
              <b>Copy of student passport</b>
            </div>
            <div>
              <b>(or copy of birth certificate, if no passport is available)</b>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">{this.renderDropzone('passport')}</div>
            <div className="col-12 col-md-6">{this.renderDroppedFiles('passport')}</div>
            {errors.admission_attachment !== undefined && errors.admission_attachment.passport !== undefined ? <div className="col-12 invalid-attachment">{errors.admission_attachment.passport}</div> : null}
          </div>
          <hr />
          <div className="mt-2">
            <b>Attach school records (in English or officially translated into English) as follows:</b>
            <ul>
              <li>K4 and K5 applicants - if the student is not a native English speaker, submit school records for at least one year of English preschool. Native English speaking students, submit any preschool records if available.</li>
              <li>Grade 1 applicants – submit the most recent report cards from the current school year and final report cards from preschool if available</li>
              <li>Grade 2 – Grade 12 applicants – submit the most recent report cards from the current school year and final report cards from the previous 2 years.</li>
              <li>If the student has a diagnosed learning disability/impairment or has had educational testing in the past, all documents, testing and medical records must be submitted and disclosed with the application</li>
            </ul>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">{this.renderDropzone('school_records')}</div>
            <div className="col-12 col-md-6">{this.renderDroppedFiles('school_records')}</div>
            {errors.admission_attachment !== undefined && errors.admission_attachment.school_records !== undefined ? <div className="col-12 invalid-attachment">{errors.admission_attachment.school_records}</div> : null}
          </div>
          <hr />
          <div className="row py-4">
            <div className="col-12 ">
              <b>International Community School does not discriminate in its admissions policy on the basis of national, ethnic, religious, or racial origin. </b>
            </div>
            <div className="col-12 pt-2">
              <button className="btn btn-primary" type="button" onClick={this.submit}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </Layout>
    )
  }
}

export default withContext(StudentApp)
