import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import React, { Component } from 'react'
import routes from './routes'
import Provider from './contexts/provider'

toast.configure()

class App extends Component {
  render() {
    return <Provider>{routes}</Provider>
  }
}

export default App
