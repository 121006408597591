import React, { Component } from 'react'
import { withContext } from '../../contexts/withContext'

class Navbar extends Component {
  render() {
    const { title, branch } = this.props
    return (
      <div>
        <div className="w-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: 'white' }}>
          <img src={`${branch.toLowerCase() === 'udon' ? '/images/ics_logo_udon.png' : '/images/ics_logo.png'}`} alt="ics-logo" className="p-2" style={{ maxWidth: '90vw', maxHeight: '95px' }} />
        </div>
        <div className="w-100 text-white d-flex justify-content-center align-items-center" style={{ backgroundColor: '#072538' }}>
          <h1 className="m-0 p-2 page-title">{title}</h1>
        </div>
      </div>
    )
  }
}

export default withContext(Navbar)
